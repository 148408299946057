import { chakra } from '@chakra-ui/react'
import Head from 'next/head'
import LayoutWithSidebar from 'src/components/layouts/LayoutWithSidebar'
import { SERVICE_NAME } from 'src/constants'
import { TaxTaskPageComponent } from './tax_tasks/components/TaxTaskPageComponent'
import { TaxTaskPageContextProvider } from './tax_tasks/contexts/TaxTaskPageContext'

const TaxTask = () => {
  return (
    <>
      <Head>
        <title>確定申告 | {SERVICE_NAME}</title>
      </Head>
      <LayoutWithSidebar>
        <chakra.main height="100vh" position="relative" backgroundColor="white">
          <TaxTaskPageContextProvider>
            <TaxTaskPageComponent />
          </TaxTaskPageContextProvider>
        </chakra.main>
      </LayoutWithSidebar>
    </>
  )
}

export default TaxTask
