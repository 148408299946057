import { Box, Flex, Image, Text } from '@chakra-ui/react'
import NextLink from 'next/link'
import { isMobile } from 'react-device-detect'
import { CommonPageHeader } from 'src/components/common/CommonPageHeader'
import { HiddenInMobile } from 'src/components/common/HiddenInMobile'
import { HiddenInPC } from 'src/components/common/HiddenInPC'
import { SPPageHeader } from 'src/components/common/SPPageHeader'
import { ROOT_PATH } from 'src/routes'
import { ProceedDiagnosisDialog } from './Dialogs/ProceedDiagnosisDialog'
import { SPFreeTrialBanner } from './SPFreeTrialBanner'
import { TaskListTabs } from './TaskListTabs'
import { EntireFlowAccordion } from './TaskListTabs/EntireFlowTabPanel'
import { TaxChart } from './TaxChart'
import { TaxReturnButton } from './TaxReturnButton'

export const TaxTaskPageComponent: React.VFC = () => {
  return (
    <>
      <Box
        backgroundColor={'inherit'}
        height={'100%'}
        overflowY={'scroll'}
        lineHeight={1}
      >
        {/* isMobileによる制御だとおかしくなってしまうのでCSSで表示を制御 */}
        <HiddenInPC>
          <SPPageHeader>
            <NextLink href={ROOT_PATH} passHref>
              <Image src="/images/logo.svg" w="128px" />
            </NextLink>
          </SPPageHeader>
        </HiddenInPC>

        <HiddenInMobile>
          <CommonPageHeader title="タスク管理" hasYearSwitcher />
        </HiddenInMobile>

        <Flex
          p={['0', '28px']}
          gap={['16px', '28px']}
          flexDir={['column-reverse', 'row']}
        >
          <Box w="100%">{!isMobile && <TaskListTabs />}</Box>
          {isMobile && (
            <Box bgColor="rgba(248, 248, 248, 0.82)" pt="44px" pb="50px">
              <Box bgColor="white.100" px="16px" py="20px">
                <Text fontSize="20px" fontWeight={700} lineHeight="20px">
                  確定申告タスク
                </Text>
                <Text
                  mt="12px"
                  mb="16px"
                  fontSize="15px"
                  fontWeight={700}
                  lineHeight="23px"
                  color="gray.700"
                >
                  上から順に進めましょう
                </Text>
                <EntireFlowAccordion />
                <TaxReturnButton />
              </Box>
              <Box
                mt="16px"
                bgColor="white.100"
                px={['16px', 0]}
                py={['20px', 0]}
              >
                <Text
                  fontSize="20px"
                  fontWeight={700}
                  lineHeight="20px"
                  mb="16px"
                >
                  納税予測
                </Text>
                <TaxChart isDemo={false} />
              </Box>
            </Box>
          )}
        </Flex>

        {/* 進め方診断のダイアログ */}
        <ProceedDiagnosisDialog />
      </Box>
      {/* {isDemo && <DemoNavigationMenu />}
      {isDemo && <DemoScreen />} */}
      {/* <PCJoyride /> */}
      <SPFreeTrialBanner />
    </>
  )
}
