import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { SERVICE_NAME } from 'src/constants'
import { useUserIsReady } from 'src/hooks/useUserIsReady'
import { PAGE_PATH } from 'src/routes'

export const ProceedDiagnosisDialog: React.FC = () => {
  const { isReady } = useUserIsReady()
  const router = useRouter()
  const { onClose } = useDisclosure()

  return (
    <Modal
      isOpen={
        !process.env.NEXT_PUBLIC_IGNORE_ONBOARDING_STATUS &&
        isReady?.is_ready === false
      }
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent w={['100%', '328px']} p="16px">
        <Flex
          h="162px"
          bgColor="gray.70"
          px={['24px', '20px']}
          align="center"
          justify="flex-start"
          gap="16px"
        >
          <Image src="/images/man-with-pc.svg" h="100px" />
          <Text
            fontSize="20px"
            lineHeight="32px"
            fontWeight={700}
            letterSpacing="2px"
          >
            <Text
              as="span"
              background="linear-gradient(transparent 50%, #FCDC4ACC 50%)"
            ></Text>

            <Text as="span" fontSize="21px" lineHeight="150%" fontWeight="700">
              スムーズな <br />
              進め方を知ろう
            </Text>
          </Text>
        </Flex>
        <Box mt="24px" mx="4px" mb="4px">
          <Text fontSize="19px" fontWeight={700}>
            進め方診断（必須）
          </Text>
          <Text
            fontSize="14px"
            lineHeight="22.4px"
            color="gray.800"
            fontWeight={400}
            mt="4px"
          >
            スムーズに{SERVICE_NAME}をご利用いただくために、
            <br />
            進め方診断にお進みください！
            <br />
            <br />
            「収入源が複数ある場合、どう進めるの？」「特殊な収入があったけど
            {SERVICE_NAME}を使えるの？」など、進め方の疑問を解消します！
          </Text>
          <Button
            w="100%"
            mt="24px"
            size="lg"
            h="48px"
            onClick={() => {
              router.push(PAGE_PATH.questions.pre_service)
            }}
          >
            診断へ進む
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  )
}
