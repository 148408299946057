import { ArrowBackIcon } from '@chakra-ui/icons'
import { Box, Flex, Image } from '@chakra-ui/react'
import NextLink from 'next/link'
import React from 'react'
import { SETTINGS_PATH } from 'src/routes'

type Props = {
  showSettings?: boolean
  previousPath?: string
}
export const SPPageHeader: React.FC<Props> = React.memo(
  ({ children, showSettings = true, previousPath }) => {
    return (
      <Flex
        position="fixed"
        align="center"
        justify="space-between"
        zIndex={2}
        boxShadow="0px 4px 20px 0px rgba(0, 0, 0, 0.08)"
        px="16px"
        h="44px"
        left={0}
        right={0}
        bgColor="white.100"
        borderBottom="1px solid"
        borderColor="gray.200"
      >
        <Box w="24px">
          {previousPath && (
            <NextLink href={previousPath} passHref>
              <ArrowBackIcon fontSize="24px" />
            </NextLink>
          )}
        </Box>
        <Flex
          justify="center"
          align="center"
          gap="8px"
          fontSize="16px"
          fontWeight={700}
          lineHeight="22px"
        >
          {children}
        </Flex>
        <Box w="24px">
          {showSettings && (
            <NextLink href={SETTINGS_PATH} passHref>
              <Image src="/icons/sidebar/settings.svg" w="24px" />
            </NextLink>
          )}
        </Box>
      </Flex>
    )
  }
)
