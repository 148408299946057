import { Box, Center, Flex, Spinner, Text, useBoolean } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useRouter } from 'next/router'
import { HiddenInPC } from 'src/components/common/HiddenInPC'
import { useActiveSubscription } from 'src/hooks/useActiveSubscription'
import { useStripeCustomerPortal } from 'src/hooks/useStripeCustomerPortal'
import { PAGE_PATH } from 'src/routes'

export const SPFreeTrialBanner: React.FC = () => {
  const [isLoading, { on }] = useBoolean()
  const { activeSubscription } = useActiveSubscription()
  const { getStripeCustomerPortalLink } = useStripeCustomerPortal()

  const router = useRouter()

  const redirectToStripeCustomerPortal = async () => {
    on()
    const url = await getStripeCustomerPortalLink(location.href)
    location.assign(url)
  }

  return !activeSubscription?.is_freetrial ? null : (
    <HiddenInPC>
      {activeSubscription?.has_stripe_subscription ? (
        <Box w="100%" pos="absolute" zIndex="999" bottom="10px" px="24px">
          <Center
            display="flex"
            background="gray.70"
            borderRadius="2px"
            p="12px 20px"
            gap="12px"
            textAlign="center"
            cursor="pointer"
            onClick={() => {
              if (!isLoading) redirectToStripeCustomerPortal()
            }}
          >
            <Text fontSize="14px" fontWeight="500">
              無料トライアル中
            </Text>
            <Text
              p="6px 12px"
              background="gray.900"
              color="white.primary"
              fontSize="13px"
              fontWeight="500"
              borderRadius="2px"
            >
              {isLoading && (
                <>
                  <Spinner w="12px" h="12px" />
                  &nbsp;
                </>
              )}
              残り
              {dayjs(activeSubscription.ended_at).diff(dayjs(), 'day')}日
            </Text>
          </Center>
        </Box>
      ) : (
        <Flex
          w="100vw"
          justify="center"
          position="fixed"
          zIndex="999"
          bottom="calc(12px + env(safe-area-inset-bottom))"
        >
          <Flex
            boxShadow="0px 4px 20px 0px rgba(0, 0, 0, 0.2)"
            bgColor="primary.orange"
            borderRadius="8px"
            px="10px"
            py="6px"
            color="white.100"
            gap="12px"
            w="100%"
            mx="16px"
            align="center"
            onClick={() => {
              router.push(PAGE_PATH.pricing_freetrial)
            }}
          >
            <Box
              py="6px"
              px="8px"
              bgColor="orange.800"
              fontWeight={500}
              lineHeight={'18.2px'}
              fontSize="13px"
              borderRadius="4px"
              textAlign="center"
            >
              無料でお試し中
              <br />
              （あと
              {dayjs(activeSubscription?.ended_at).diff(dayjs(), 'day')}
              日）
            </Box>
            <Text fontWeight={700} lineHeight={1} fontSize="15px">
              有料プランに移行する
            </Text>
          </Flex>
        </Flex>
      )}
    </HiddenInPC>
  )
}
